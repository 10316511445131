import Icon from "@ant-design/icons";
const CowIcon = (props: any) => (
  <Icon
    component={() => (
      <svg
        style={{
          width: "1.2em",
          height: "1.2em",
          verticalAlign: "middle",
          fill: "currentcolor",
          overflow: "hidden",
        }}
        viewBox="0 0 500 500"
        version="1.0"
      >
        <g
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M1538 4082 c-75 -80 -139 -175 -197 -290 l-42 -83 -65 60 c-161 147
-388 213 -660 190 -112 -9 -287 -39 -338 -59 -114 -43 -39 -268 153 -458 222
-220 495 -303 731 -223 47 16 88 27 93 24 4 -2 7 -50 7 -106 0 -178 21 -310
100 -628 62 -248 94 -409 115 -579 9 -74 17 -139 19 -144 1 -4 18 16 37 45 64
97 147 185 299 319 187 164 258 242 299 325 48 96 64 188 58 330 -6 150 -33
238 -123 398 -105 185 -162 301 -178 364 -11 41 -16 106 -16 199 l0 139 -50
27 c-56 32 -112 100 -137 171 -10 26 -23 47 -29 47 -6 0 -40 -31 -76 -68z"
          />
          <path
            d="M3477 4103 c-25 -71 -81 -139 -137 -171 l-50 -27 0 -139 c0 -93 -5
-158 -16 -199 -16 -63 -73 -179 -178 -364 -90 -160 -117 -248 -123 -398 -6
-142 10 -234 58 -330 41 -83 112 -161 299 -325 152 -134 235 -222 299 -319
l35 -53 22 163 c27 197 49 311 114 569 79 317 100 449 100 627 0 56 3 104 8
106 4 3 45 -8 92 -24 188 -64 391 -27 591 107 268 180 441 518 293 574 -51 20
-226 50 -338 59 -272 23 -499 -43 -660 -190 l-65 -60 -42 83 c-23 46 -62 114
-87 153 -50 75 -168 205 -186 205 -6 0 -19 -21 -29 -47z"
          />
          <path
            d="M1924 1476 c-65 -29 -99 -111 -92 -225 6 -95 34 -160 96 -224 55 -56
103 -73 160 -57 74 19 114 92 114 205 -1 130 -63 248 -155 291 -59 27 -79 29
-123 10z"
          />
          <path
            d="M3073 1465 c-134 -67 -200 -285 -129 -423 49 -94 153 -102 243 -19
55 50 80 99 99 187 24 118 -13 228 -91 264 -45 22 -63 20 -122 -9z"
          />
        </g>
      </svg>
    )}
    {...props}
  />
);

export default CowIcon;
