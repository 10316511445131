import { ReactNode } from "react";
import { Link, Route } from "react-router-dom";
import Layout from "../layout/Layout";
import AnimalPage from "../pages/AnimalPage";
import ReportPage from "../pages/ReportPage";
import { LogoutOutlined, SnippetsOutlined } from "@ant-design/icons";
import { AuthHelper } from "../core/helper/AuthHelper";
import CowIcon from "../Icons/CowIcon";
import ConsumptionCrudPage from "../pages/ConsumptionCrudPage";
import CounterIcon from "../Icons/CounterIcon";

export const getPrivateRoutes = () => {
  return (
    <>
      {getRoute(<ReportPage />, "/")}
      {getRoute(<ReportPage />, "/gorev-gecmisi")}
      {getRoute(<AnimalPage />, "/suru-bilgileri")}
      {getRoute(<ConsumptionCrudPage />, "/sarfiyat-bilgileri")}
    </>
  );
};

const getRoute = (component: ReactNode, path: string) => {
  return <Route path={path} element={<Layout>{component}</Layout>} />;
};

export const getMenuItems = () => {
  return [
    {
      label: <Link to="/gorev-gecmisi">Görev Geçmişi</Link>,
      key: "gorev-gecmisi",
      icon: <SnippetsOutlined />,
    },
    {
      label: <Link to="/suru-bilgileri">Sürü Bilgileri</Link>,
      key: "suru-bilgileri",
      icon: <CowIcon />,
    },
    {
      label: <Link to="/sarfiyat-bilgileri">Sarfiyat Bilgileri</Link>,
      key: "sarfiyat-bilgileri",
      icon: <CounterIcon />,
    },
    {
      label: <span onClick={AuthHelper.logout}>Çıkış Yap</span>,
      key: "cikis",
      icon: <LogoutOutlined />,
    },
  ];
};
