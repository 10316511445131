import axios from "axios";
import { toast } from "react-toastify";

const baseURL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
  baseURL: baseURL,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.response.use(
  (response: any) => {
    return response;
  },
  async (error: any) => {
    toast.error(error.response.data.message ?? "");
    return Promise.reject(error);
  }
);

export default api;
