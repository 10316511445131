import Cookies from "js-cookie";
import { useEffect, useRef, useState } from "react";
import requestApi from "../client/EntityApi";
import CrudPageProcessButton from "../core/component/crud/CrudPageProcessButton";
import CrudTable, {
  ICrudTableRefMethods,
} from "../core/component/crud/CrudTable";
import { MedicineBoxOutlined, LoadingOutlined } from "@ant-design/icons";
import PeriodSelect from "../components/PeriodSelect";
import {
  getReportPageColumns,
  getReportPageExpandedRowRender,
} from "../components/crud/ReportPageComponents";
import { Col, Modal, Row } from "antd";
import VaccineCard from "../components/VaccineCard";

const ReportPage = () => {
  const crudPageRef = useRef<ICrudTableRefMethods>(null);

  const [startPeriodId, setStartPeriodId] = useState<number>();
  const [finishPeriodId, setFinishPeriodId] = useState<number>();
  const [subdomain, setSubdomain] = useState<string>();

  const [vaccineLogs, setVaccineLogs] = useState<any[]>([]);
  const [vaccineLogsLoading, setVaccineLogsLoading] = useState<boolean>(false);
  const [vaccineLogsModalIsOpen, setVaccineLogsModalIsOpen] =
    useState<boolean>(false);

  useEffect(() => {
    const ıriParts = window.location.hostname.split(".");
    setSubdomain(ıriParts[0] !== "www" ? ıriParts[0] : ıriParts[1]);
  }, []);

  useEffect(() => {
    if (startPeriodId && finishPeriodId) crudPageRef.current?.refreshData();
  }, [startPeriodId, finishPeriodId]);

  const getAll = () => {
    const key = Cookies.get("key");
    const psw = Cookies.get("psw");
    return key && psw && subdomain
      ? requestApi.getCompanyReports(
          subdomain,
          key,
          psw,
          "companyReport",
          startPeriodId,
          finishPeriodId
        )
      : new Promise((resolve, reject) => {
          resolve([]);
          reject();
        });
  };

  const getVaccineLogs = () => {
    const key = Cookies.get("key");
    const psw = Cookies.get("psw");
    if (key && psw && subdomain) {
      setVaccineLogsLoading(true);
      requestApi
        .getCompanyReports(subdomain, key, psw, "vaccineLog")
        .then((response: any) => setVaccineLogs(response))
        .finally(() => setVaccineLogsLoading(false));
    }
  };

  return (
    <>
      <CrudTable
        ref={crudPageRef}
        entityLabel=""
        customTableTitle={() => <>Görev Kategorileri</>}
        api={{}}
        getAll={getAll}
        columns={getReportPageColumns()}
        hideAddProcess
        hideDefaultRowProceses
        hideSearchBar
        beforeRefreshOperation={(data) => data}
        afterRefreshOperation={() => getVaccineLogs()}
        expandable={{
          expandedRowRender: (record) => getReportPageExpandedRowRender(record),
        }}
        extraTitleProcess={() => (
          <>
            <CrudPageProcessButton
              tooltipText="Aşı Geçmişini Göster"
              icon={<MedicineBoxOutlined />}
              onClick={() => setVaccineLogsModalIsOpen(true)}
            />
            <PeriodSelect
              style={{ marginRight: "1em" }}
              selectedPeriodId={startPeriodId}
              setSelectedPeriodId={setStartPeriodId}
            />
            <PeriodSelect
              style={{ marginRight: "1em" }}
              selectedPeriodId={finishPeriodId}
              setSelectedPeriodId={setFinishPeriodId}
            />
          </>
        )}
      />
      <Modal
        title="Aşı Geçmişini Göster"
        visible={vaccineLogsModalIsOpen}
        okButtonProps={{ hidden: true }}
        cancelText="Kapat"
        onCancel={() => {
          setVaccineLogs([]);
          setVaccineLogsLoading(false);
          setVaccineLogsModalIsOpen(false);
        }}
      >
        {vaccineLogsLoading ? (
          <Row justify="center">
            <LoadingOutlined />
          </Row>
        ) : (
          <Row>
            <Col span={24}>
              {vaccineLogs.map((vaccineLog: any, index: number) => {
                return <VaccineCard key={index} vaccine={vaccineLog} />;
              })}
            </Col>
          </Row>
        )}
      </Modal>
    </>
  );
};

export default ReportPage;
