import dayjs from "dayjs";
import { getDateString } from "../../core/helper/UtilHelper";

export const getConsumptionCrudPageColumns = () => {
  return [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Ürün",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Verilen Tarih",
      dataIndex: "date",
      key: "date",
      render: (value: any) => getDateString(value),
    },
    {
      title: "Stok",
      dataIndex: "stock",
      key: "stock",
    },
    {
      title: "Önerilen Miktar",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Kullanım Dozu",
      dataIndex: "dose",
      key: "dose",
    },
    {
      title: "Kalan Miktar",
      key: "remainderQuantity",
      render: (_value: any, row: any) => {
        const now = dayjs();
        const daily = row.quantity * row.dose;
        const usedDay = now.diff(row.date, "day");
        const usedQuantity = daily * usedDay;
        const remainderQuantity = row.stock - usedQuantity;
        return (remainderQuantity > 0 ? remainderQuantity : 0).toFixed(2);
      },
    },
    {
      title: "Kalan Gün Sayısı",
      key: "remainderDay",
      render: (_value: any, row: any) => {
        const now = dayjs();
        const daily = row.quantity * row.dose;
        const usedDay = now.diff(row.date, "day");
        const usedQuantity = daily * usedDay;
        const remainderQuantity = row.stock - usedQuantity;
        return (remainderQuantity / daily).toFixed(2);
      },
    },
  ];
};
