import YesNoTag from "../../core/component/YesNoTag";
import {
  cellStyle,
  getConstantValue,
  getDateString,
} from "../../core/helper/UtilHelper";

export const getAnimalPageColumns = (
  animalStatus: any[],
  remainderState: string
) => {
  return [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Küpe No",
      dataIndex: "earringNo",
      key: "earringNo",
    },
    {
      title: "İşletme Id",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "Son Tohumlama Tarihi",
      dataIndex: "lastSemination",
      key: "lastSemination",
      render: (value: any) =>
        value ? getDateString(value.date) : "Tohum Atılmadı",
    },
    {
      title: "Son Doğurma Tarihi",
      dataIndex: "lastBirth",
      key: "lastBirth",
      render: (value: any) =>
        value ? getDateString(value.date) : "Doğum Yapmadı",
    },
    {
      title: "SGS",
      dataIndex: "dsg",
      key: "dsg",
      sorter: (a: any, b: any) => a.dsg - b.dsg,
    },
    {
      title: "pSGS",
      dataIndex: "sgs",
      key: "sgs",
      sorter: (a: any, b: any) => a.sgs - b.sgs,
    },
    {
      title: "GSV",
      dataIndex: "gsv",
      key: "gsv",
      sorter: (a: any, b: any) => a.gsv - b.gsv,
    },
    {
      title: "TS",
      dataIndex: "ts",
      key: "ts",
      sorter: (a: any, b: any) => a.ts - b.ts,
    },
    {
      title: "Gebelik",
      dataIndex: "pregnancyDay",
      key: "pregnancyDay",
      sorter: (a: any, b: any) => a.pregnancyDay - b.pregnancyDay,
    },
    {
      title: "Durum",
      dataIndex: "state",
      key: "state",
      filters: animalStatus,
      onFilter: (value: any, record: any) => record.state === value,
    },
    {
      title: "Kalan Süre",
      key: "remainderTime",
      sorter: (a: any, b: any) => a[remainderState] - b[remainderState],
      render: (_value: any, row: any) => row[remainderState] ?? 0,
    },
    {
      title: "Sürüde",
      dataIndex: "isActive",
      key: "isActive",
      render: (value: any) => (
        <YesNoTag
          success={value}
          successText="Sürüde"
          failText="Sürüden Çıkarılmış"
        />
      ),
      defaultFilteredValue: [true],
      filters: [
        {
          text: "Sürüde",
          value: true,
        },
        {
          text: "Sürüden Çıkarılmış",
          value: false,
        },
      ],
      onFilter: (value: any, record: any) => record.isActive === value,
    },
  ];
};

export const getAnimalPageExpandedRowRender = (
  record: any,
  genders: any[],
  racials: any[],
  seminationStates: any[],
  birthTypes: any[]
) => {
  return (
    <>
      <table style={{ minWidth: "100%", marginBottom: "1em" }}>
        <thead>
          <tr>
            <th style={cellStyle}>Yaş (Gün)</th>
            <th style={cellStyle}>Yaş (Ay)</th>
            <th style={cellStyle}>Yaş (Yıl)</th>
            <th style={cellStyle}>Doğum Tarihi</th>
            <th style={cellStyle}>Cinsiyet</th>
            <th style={cellStyle}>Irk</th>
            <th style={cellStyle}>Padok</th>
            <th style={cellStyle}>Ana Adı</th>
            <th style={cellStyle}>Baba Adı</th>
            <th style={cellStyle}>Doğum Ağırlığı</th>
            <th style={cellStyle}>Sütten Kesme Ağırlığı</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th style={cellStyle}>{record.birthDay}</th>
            <th style={cellStyle}>{record.birthMonth}</th>
            <th style={cellStyle}>{record.birthYear}</th>
            <th style={cellStyle}>{getDateString(record.birthDate)}</th>
            <th style={cellStyle}>
              {getConstantValue(record.gender, genders)}
            </th>
            <th style={cellStyle}>
              {getConstantValue(record.racial, racials)}
            </th>
            <th style={cellStyle}>{record.paddock}</th>
            <th style={cellStyle}>{record.mother}</th>
            <th style={cellStyle}>{record.father}</th>
            <th style={cellStyle}>{record.birthWeight}</th>
            <th style={cellStyle}>{record.weaningWeight}</th>
          </tr>
        </tbody>
      </table>
      <table
        style={{
          width: "50%",
          textAlign: "center",
          borderCollapse: "collapse",
          border: "1px solid black",
          margin: "1em 0px 1em 0px",
        }}
      >
        <thead>
          <tr style={cellStyle}>
            <th style={cellStyle} colSpan={7}>
              Tohum Bilgileri ({record.seminations.length} Defa Tohumlandı)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr style={cellStyle}>
            <th style={cellStyle}>Sperma</th>
            <th style={cellStyle}>Tohum Atan</th>
            <th style={cellStyle}>USG1</th>
            <th style={cellStyle}>USG1'i Yapan</th>
            <th style={cellStyle}>USG2</th>
            <th style={cellStyle}>USG2'i Yapan</th>
            <th style={cellStyle}>Tohum Tarihi</th>
          </tr>
          {record.seminations.map((semination: any, index: number) => {
            return (
              <tr style={cellStyle} key={index}>
                <th style={cellStyle}>{semination.sperma}</th>
                <th style={cellStyle}>
                  {semination.seminationWorker
                    ? semination.seminationWorker
                    : "BELİRSİZ"}
                </th>
                <th style={cellStyle}>
                  {getConstantValue(semination.usg1, seminationStates)}
                </th>
                <th style={cellStyle}>{semination.usg1Worker}</th>
                <th style={cellStyle}>
                  {getConstantValue(semination.usg2, seminationStates)}
                </th>
                <th style={cellStyle}>{semination.usg2Worker}</th>
                <th style={cellStyle}>{getDateString(semination.date)}</th>
              </tr>
            );
          })}
        </tbody>
      </table>
      <table
        style={{
          width: "50%",
          textAlign: "center",
          borderCollapse: "collapse",
          border: "1px solid black",
          margin: "1em 0px 1em 0px",
        }}
      >
        <thead>
          <tr style={cellStyle}>
            <th style={cellStyle} colSpan={5}>
              Doğum Bilgileri ({record.births.length} Defa Doğurdu)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr style={cellStyle}>
            <th style={cellStyle}>Doğum Tipi</th>
            <th style={cellStyle}>Açıklaması</th>
            <th style={cellStyle}>Doğurma Tarihi</th>
          </tr>
          {record.births.map((birth: any, index: number) => {
            return (
              <tr style={cellStyle} key={index}>
                <th style={cellStyle}>
                  {getConstantValue(birth.type, birthTypes)}
                </th>
                <th style={cellStyle}>{birth.description}</th>
                <th style={cellStyle}>{getDateString(birth.date)}</th>
              </tr>
            );
          })}
        </tbody>
      </table>
      <table
        style={{
          width: "50%",
          textAlign: "center",
          borderCollapse: "collapse",
          border: "1px solid black",
          margin: "1em 0px 1em 0px",
        }}
      >
        <thead>
          <tr style={cellStyle}>
            <th style={cellStyle} colSpan={5}>
              Süt Bilgileri ({record.milkRates.length} Kayıt Mevcut)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr style={cellStyle}>
            <th style={cellStyle}>Veri</th>
            <th style={cellStyle}>Tarih</th>
          </tr>
          {record.milkRates.map((milkRate: any, index: number) => {
            return (
              <tr style={cellStyle} key={index}>
                <th style={cellStyle}>{milkRate.data}</th>
                <th style={cellStyle}>{getDateString(milkRate.date)}</th>
              </tr>
            );
          })}
        </tbody>
      </table>
      <table
        style={{
          width: "50%",
          textAlign: "center",
          borderCollapse: "collapse",
          border: "1px solid black",
          margin: "1em 0px 1em 0px",
        }}
      >
        <thead>
          <tr style={cellStyle}>
            <th style={cellStyle} colSpan={5}>
              Senkronizasyon Bilgileri ({record.syncs.length} Kayıt Mevcut)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr style={cellStyle}>
            <th style={cellStyle}>Başlangıç T.</th>
            <th style={cellStyle}>PG Tarihi</th>
            <th style={cellStyle}>GNRH2 Tarihi</th>
            <th style={cellStyle}>Tohumlama Tarihi</th>
            <th style={cellStyle}>Senkronizasyonun Bittiği Tarih</th>
          </tr>
          {record.syncs.map((sync: any, index: number) => {
            return (
              <tr style={cellStyle} key={index}>
                <th style={cellStyle}>{getDateString(sync.date)}</th>
                <th style={cellStyle}>{getDateString(sync.pg)}</th>
                <th style={cellStyle}>{getDateString(sync.gnrh2)}</th>
                <th style={cellStyle}>{getDateString(sync.semination)}</th>
                <th style={cellStyle}>
                  {sync.passiveDate ? getDateString(sync.passiveDate) : "-"}
                </th>
              </tr>
            );
          })}
        </tbody>
      </table>
      <table
        style={{
          width: "50%",
          textAlign: "center",
          borderCollapse: "collapse",
          border: "1px solid black",
          margin: "1em 0px 1em 0px",
        }}
      >
        <thead>
          <tr style={cellStyle}>
            <th style={cellStyle} colSpan={5}>
              Aşı Bilgileri ({record.individualVaccines.length} Kayıt Mevcut)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr style={cellStyle}>
            <th style={cellStyle}>Aşı İsmi</th>
            <th style={cellStyle}>Açıklaması</th>
            <th style={cellStyle}>Yapılma Tarihi</th>
          </tr>
          {record.individualVaccines.map(
            (individualVaccine: any, index: number) => {
              return (
                <tr style={cellStyle} key={index}>
                  <th style={cellStyle}>{individualVaccine.name}</th>
                  <th style={cellStyle}>{individualVaccine.description}</th>
                  <th style={cellStyle}>
                    {individualVaccine.date
                      ? getDateString(individualVaccine.date)
                      : "-"}
                  </th>
                </tr>
              );
            }
          )}
        </tbody>
      </table>

      {record.description && (
        <p style={{ color: "red" }}>
          <b>Sürüden Çıkartılma Sebebi</b> : {record.description}
        </p>
      )}
    </>
  );
};
