import SidebarLayout from "../core/layout/SidebarLayout";
import { getMenuItems } from "../navigation/Navigations";
import { LogoutOutlined } from "@ant-design/icons";
import HeaderBadge from "../core/component/HeaderBadge";
import { AuthHelper } from "../core/helper/AuthHelper";

const Layout = (props: any) => {
  return (
    <SidebarLayout
      title={
        <img
          width={70}
          height={40}
          alt="logo"
          src="https://www.logomaker.com/api/main/images/1j+ojVVCOMkX9Wyrexe4hGfKx6jTuktNlQiRjmpmYzBL9A5olyYukLc1rLx+fxAB51QCihENcs86nnUlDt5c0UUgvDKM"
        />
      }
      //TODO: Logo değişecek
      navigations={getMenuItems()}
      extra={() => (
        <>
          <HeaderBadge
            tooltipText="Çıkış Yap"
            avatarIcon={<LogoutOutlined />}
            onClick={() => AuthHelper.logout()}
          />
        </>
      )}
    >
      {props.children}
    </SidebarLayout>
  );
};

export default Layout;
