import api from "../core/client/AxiosConfig";

const requestApi = {
  getCompanyReports: (
    ıri: string,
    username: string,
    password: string,
    page: string,
    startPeriod?: number,
    finishPeriod?: number
  ) => {
    return api
      .post("api/report-cover", {
        sub: ıri,
        key: username,
        psw: password,
        page: page,
        startPeriod: startPeriod,
        finishPeriod: finishPeriod,
      })
      .then((response) => {
        return response.data;
      });
  },
  getAll: () => {
    return api.get("api/periods").then((response) => {
      return response.data;
    });
  },
  getCurrentPeriod: () => {
    return api.get("/api/current-period").then((response) => {
      return response.data;
    });
  },
};

export default requestApi;
