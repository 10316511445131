import Cookies from "js-cookie";
import requestApi from "../../client/EntityApi";

export const AuthHelper = {
  checkLogin: (subdomain: string, username: string, password: string) => {
    return requestApi.getCompanyReports(subdomain, username, password, "");
  },
  logout: () => {
    Cookies.remove("key");
    Cookies.remove("psw");
    window.location.replace(window.location.origin);
  },
  isLoggedIn: () => {
    return Boolean(Cookies.get("key"));
  },
};
