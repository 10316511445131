import { Button, Card, Form, Input } from "antd";
import Cookies from "js-cookie";
import { useState } from "react";
import { AuthHelper } from "../helper/AuthHelper";
import { Rules } from "../Rules";

const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#7598e7",
      }}
    >
      <Card
        style={{ width: "600px", borderRadius: "5px" }}
        title={
          <div style={{ textAlign: "center" }}>
            <h2>
              <b>Sync</b>
            </h2>
            <h3>
              <b>İş Takip Sistemi</b>
            </h3>
          </div>
        }
      >
        <Form
          autoComplete="off"
          size="large"
          onFinish={(values: any) => {
            setLoading(true);
            const ıriParts = window.location.hostname.split(".");
            const subdomain = ıriParts[0] !== "www" ? ıriParts[0] : ıriParts[1];
            AuthHelper.checkLogin(subdomain, values.username, values.password)
              .then(() => {
                Cookies.set("key", values.username);
                Cookies.set("psw", values.password);
                window.location.reload();
              })
              .finally(() => setLoading(false));
          }}
        >
          <Form.Item name="username" rules={[Rules.required, Rules.min(3)]}>
            <Input
              style={{ borderRadius: "5px" }}
              placeholder="Kullanıcı Adı"
            />
          </Form.Item>
          <Form.Item name="password" rules={[Rules.required, Rules.min(4)]}>
            <Input.Password
              style={{ borderRadius: "5px" }}
              placeholder="Şifre"
            />
          </Form.Item>
          <Form.Item>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              style={{ width: "100%", borderRadius: "5px" }}
            >
              Giriş Yap
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default LoginPage;
