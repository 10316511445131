import { Select } from "antd";
import Cookies from "js-cookie";
import { useEffect, useRef, useState } from "react";
import requestApi from "../client/EntityApi";
import AnimalReportBar from "../components/AnimalReportBar";
import {
  getAnimalPageColumns,
  getAnimalPageExpandedRowRender,
} from "../components/crud/AnimalPageComponents";
import CrudTable, {
  ICrudTableRefMethods,
} from "../core/component/crud/CrudTable";

const AnimalPage = () => {
  const crudPageRef = useRef<ICrudTableRefMethods>(null);

  const [report, setReport] = useState<any>();
  const [subdomain, setSubdomain] = useState<string>();
  const [remainderState, setRemainderState] = useState("dryRemainder");
  const [genders, setGenders] = useState<any[]>([]);
  const [racials, setRacials] = useState<any[]>([]);
  const [animalStatus, setAnimalStatus] = useState<any[]>([]);
  const [seminationStates, setSeminationStates] = useState<any[]>([]);
  const [birthTypes, setBirthTypes] = useState<any[]>([]);

  useEffect(() => {
    const ıriParts = window.location.hostname.split(".");
    setSubdomain(ıriParts[0] !== "www" ? ıriParts[0] : ıriParts[1]);
  }, []);

  useEffect(() => {
    crudPageRef.current?.refreshData();
  }, [subdomain]);

  const getAll = () => {
    const key = Cookies.get("key");
    const psw = Cookies.get("psw");
    return key && psw && subdomain
      ? requestApi.getCompanyReports(subdomain, key, psw, "animals")
      : new Promise((resolve, reject) => {
          resolve([]);
          reject();
        });
  };

  const getConstants = () => {
    const key = Cookies.get("key");
    const psw = Cookies.get("psw");
    if (key && psw && subdomain) {
      requestApi
        .getCompanyReports(subdomain, key, psw, "constants")
        .then((response) => {
          setGenders(response["gender"]);
          setRacials(response["racial"]);
          setAnimalStatus(response["animalStatus"]);
          setSeminationStates(response["seminationState"]);
          setBirthTypes(response["birthType"]);
        });
    }
  };

  const getAnimalReport = () => {
    const key = Cookies.get("key");
    const psw = Cookies.get("psw");
    if (key && psw && subdomain) {
      requestApi
        .getCompanyReports(subdomain, key, psw, "animalReport")
        .then((response) => setReport(response));
    }
  };

  return (
    <>
      <AnimalReportBar report={report} />
      <CrudTable
        ref={crudPageRef}
        entityLabel=""
        customTableTitle={() => <>Sürü Listesi</>}
        api={{}}
        getAll={getAll}
        columns={getAnimalPageColumns(animalStatus, remainderState)}
        hideAddProcess
        hideDefaultRowProceses
        searchKeys={["earringNo", "companyName"]}
        expandable={{
          expandedRowRender: (record) => {
            return getAnimalPageExpandedRowRender(
              record,
              genders,
              racials,
              seminationStates,
              birthTypes
            );
          },
        }}
        extraTitleProcess={() => (
          <Select
            style={{ marginRight: "1em" }}
            onSelect={(value: string) => setRemainderState(value)}
            options={[
              { label: "Kuru", value: "dryRemainder" },
              { label: "CloseUp", value: "closeupRemainder" },
              { label: "Fresh", value: "freshRemainder" },
              { label: "Sütten Kes.", value: "weaningRemainder" },
              { label: "Doğuma", value: "birthRemainder" },
            ]}
            defaultValue="dryRemainder"
          />
        )}
        beforeRefreshOperation={(data) => data}
        afterRefreshOperation={() => {
          getConstants();
          getAnimalReport();
        }}
      />
    </>
  );
};

export default AnimalPage;
