import Cookies from "js-cookie";
import { useEffect, useRef, useState } from "react";
import { getConsumptionCrudPageColumns } from "../components/crud/ConsumptionPageComponents";
import CrudTable, {
  ICrudTableRefMethods,
} from "../core/component/crud/CrudTable";
import requestApi from "../client/EntityApi";

const ConsumptionCrudPage = () => {
  const crudPageRef = useRef<ICrudTableRefMethods>(null);
  const [subdomain, setSubdomain] = useState<string>();

  useEffect(() => {
    const ıriParts = window.location.hostname.split(".");
    setSubdomain(ıriParts[0] !== "www" ? ıriParts[0] : ıriParts[1]);
  }, []);

  useEffect(() => {
    crudPageRef.current?.refreshData();
  }, [subdomain]);

  const getAll = () => {
    const key = Cookies.get("key");
    const psw = Cookies.get("psw");
    return key && psw && subdomain
      ? requestApi.getCompanyReports(subdomain, key, psw, "consumptions")
      : new Promise((resolve, reject) => {
          resolve([]);
          reject();
        });
  };

  return (
    <CrudTable
      ref={crudPageRef}
      entityLabel="Sarfiyat"
      api={{}}
      getAll={getAll}
      searchKeys={["productName"]}
      columns={getConsumptionCrudPageColumns()}
      hideDefaultRowProceses
      hideAddProcess
      beforeRefreshOperation={(data) => data}
    />
  );
};

export default ConsumptionCrudPage;
