import { Typography, Image } from "antd";
import { getDateTimeString, isImageMedia } from "../../core/helper/UtilHelper";
import FeedScreenTable from "../FeedScreenTable";
import FeedTable from "../FeedTable";
import GaitaTable from "../GaitaTable";
import RationTable from "../RationTable";
import VaccineCard from "../VaccineCard";

export const getReportPageExpandedRowRender = (record: any) => {
  const { Title, Paragraph, Text, Link } = Typography;
  return (
    <>
      <Typography>
        <Title level={5}>Açıklama</Title>
        <Paragraph> {record.description}</Paragraph>
        {record.vaccines.length > 0 && (
          <>
            <Title level={5}>Aşı Listesi</Title>
            <Paragraph>
              {record.vaccines.map((vaccine: any, index: number) => (
                <VaccineCard key={index} vaccine={vaccine} />
              ))}
            </Paragraph>
          </>
        )}
        {record.rationTables.length > 0 && (
          <>
            <Title level={5}>Rasyon Tabloları</Title>
            <Paragraph>
              {record.rationTables.map((rationTable: any, index: number) => (
                <RationTable key={index} rationTable={rationTable} />
              ))}
            </Paragraph>
          </>
        )}
        {record.baitAnalyseTables.length > 0 && (
          <>
            <Title level={5}>Yem Analiz Tabloları</Title>
            <Paragraph>
              {record.baitAnalyseTables.map(
                (baitAnalyseTable: any, index: number) => (
                  <FeedTable key={index} baitTable2={baitAnalyseTable} />
                )
              )}
            </Paragraph>
          </>
        )}
        {record.gaitaTables.length > 0 && (
          <>
            <Title level={5}>Gaita Analiz Tabloları</Title>
            <Paragraph>
              {record.gaitaTables.map((gaitaTable: any, index: number) => (
                <GaitaTable key={index} gaitaTable={gaitaTable} />
              ))}
            </Paragraph>
          </>
        )}
        {record.baitTables.length > 0 && (
          <>
            <Title level={5}>Yem Elek Analiz Tabloları</Title>
            <Paragraph>
              {record.baitTables.map((baitTable: any, index: number) => (
                <FeedScreenTable key={index} baitTable={baitTable} />
              ))}
            </Paragraph>
          </>
        )}
        <Title level={5}>Medya Listesi</Title>
        <Paragraph>
          <ul>
            {record.media.map((media: any, index: number) => {
              return (
                !isImageMedia(media.extension) && (
                  <li key={index}>
                    <Link
                      target="_blank"
                      href={process.env.REACT_APP_BASE_URL + media.uid}
                    >
                      {media.name}
                    </Link>
                  </li>
                )
              );
            })}
          </ul>
        </Paragraph>
        <Title level={5}>Görseller</Title>
        {record.media.map((media: any, index: number) => {
          return (
            isImageMedia(media.extension) && (
              <Image
                key={index}
                width={200}
                src={process.env.REACT_APP_BASE_URL + media.uid}
              />
            )
          );
        })}
        {record.errorComment && (
          <>
            <Title level={5}>Yönetici Yorumu</Title>
            <Paragraph>
              <Text type="danger"> {record.errorComment}</Text>
            </Paragraph>
          </>
        )}
      </Typography>
    </>
  );
};

export const getReportPageColumns = () => {
  return [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Personel",
      dataIndex: "worker",
      key: "worker",
      render: (value: any) => value.name + " " + value.surname,
    },
    {
      title: "Bildiri Kategorisi",
      dataIndex: "taskCategory",
      key: "taskCategory",
      render: (value: any) => value.service.name,
    },
    {
      title: "Bildiri Zamanı",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value: any) => getDateTimeString(value),
    },
  ];
};
